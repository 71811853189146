import { Form, Row, Col, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

function ScriptView() {
  return (
    <Row>
      <Col>
        
      </Col>
    </Row>
  );
}

export default ScriptView;