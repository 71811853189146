import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";

function PluginView() {
  return (
    <Container className="mt-3">
      Plugins
    </Container>
  );
}

export default PluginView;