
import { Link, useLocation } from "react-router-dom";

import { Navbar, Container, Breadcrumb } from 'react-bootstrap';
import './footer.style.css';

function FooterView() {
  return (
    <Navbar className="footer" bg="dark">
      <Container>
        A
      </Container>
    </Navbar>
  );
}

export default FooterView;